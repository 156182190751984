import React from "react";

import { Seo } from "~components";

import { Layout } from "~components/login";
import { LoginForm } from "~forms/login";
import { withAuth } from "~lib/helpers";

const Login = () => {
  return (
    <>
      <Seo title="Login" />
      <Layout minH="100vh">
        <LoginForm />
      </Layout>
    </>
  );
};

export default withAuth(Login);
