/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { FC, useState } from "react";
import { yupResolver } from "@hookform/resolvers";
import {
  Box,
  Flex,
  PrimaryButton,
  Radio,
  RadioGroup,
  Stack,
  Text,
  SecondaryButton,
  UnderlineButton,
} from "flicket-ui";
import { motion, AnimatePresence } from "framer-motion";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { Controller } from "~components";
import { useUser, useOrganization } from "~hooks";
import { handlePromise, showToast, getError } from "~lib/helpers";
import { useReCaptchaSDK } from "~hooks/useReCaptcha";

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const schemaMagicLink = yup.object().shape({
  email: yup.string().email().required(),
});

export const LoginForm: FC = () => {
  const { organization } = useOrganization();
  const [mode, setFormType] = useState("password");
  const { handleSubmit, formState, control, errors, reset } = useForm({
    defaultValues: { email: "", password: "" },
    resolver: yupResolver(mode === "magic" ? schemaMagicLink : schema),
  });
  const [isSuccessful, setSuccessful] = useState(false);
  const { login } = useUser();
  const { recaptchaSdkGenerator } = useReCaptchaSDK();

  return (
    <>
      {isSuccessful ? (
        <>
          <Text
            fontSize={6}
            mb={3}
            fontWeight="heavy"
            color="N800"
            textAlign="center"
          >
            Login link has been sent!
          </Text>

          <Text variant="paragraph" mb={2}>
            If you can’t find the email check your spam folder or click the link
            below.
          </Text>
          <SecondaryButton
            onClick={() => {
              reset();
              setSuccessful(false);
            }}
          >
            Try again
          </SecondaryButton>
        </>
      ) : (
        <>
          <Text fontSize={6} fontWeight="heavy" color="N800" textAlign="center">
            Sign-in
          </Text>
          <Stack
            flexDir="column"
            alignItems="flex-start"
            space={5}
            mt={3}
            mb={3}
            width={{ default: "100%", sm: 380 }}
          >
            <RadioGroup
              toggle
              value={mode}
              onChange={(mode) => setFormType(mode)}
              space={0}
              big
            >
              <Radio value="magic">
                <Text>Send me a login link</Text>
              </Radio>
              <Radio value="password">Password login</Radio>
            </RadioGroup>
          </Stack>
        </>
      )}
      <Flex
        as="form"
        onSubmit={handleSubmit(async (input) => {
          if (mode === "magic") {
            const [error, data] = await handlePromise(async () => {
              const sdkFn = await recaptchaSdkGenerator("requestMagicLink");
              return await sdkFn({ orgId: organization.id }).requestMagicLink({
                input: { email: input.email },
              });
            });

            if (error) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              showToast(getError(error, "graphQL"), "error");
              return;
            } else if (data) {
              showToast(
                "We have sent you a login link! Please check your email.",
                "success"
              );
              setSuccessful(true);
              return;
            }
          }
          await login(input);
        })}
        width={{ default: "100%", sm: 380 }}
        flexDirection="column"
      >
        {!isSuccessful && (
          <>
            <Controller
              label="E-mail"
              name="email"
              control={control}
              errors={errors}
              type="email"
              mb={3}
              autoFocus
            />
            <AnimatePresence>
              {mode === "password" && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto" }}
                  exit={{ opacity: 0, height: 0 }}
                >
                  <Controller
                    control={control}
                    errors={errors}
                    label="Password"
                    name="password"
                    type="password"
                    mb={3}
                  />
                </motion.div>
              )}
            </AnimatePresence>
            <PrimaryButton
              type="submit"
              isLoading={formState.isSubmitting}
              width="100%"
              lineHeight="normal"
              boxShadow="button"
            >
              Log-in
            </PrimaryButton>
          </>
        )}
        <Box mt="6/4" mx={"auto" as any}>
          <UnderlineButton to="/forgot-password" color="N600">
            Forgot your password?
          </UnderlineButton>
        </Box>
      </Flex>
    </>
  );
};
